.page {
  flex:1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
}

.title {
  color: #666666;
  font-size: 1.5em;
  margin: 1em;
  display: inline-block;
  text-align: center;
}

.tags {
  margin-top: 1em;
  margin-bottom: 2em;
  text-align: center;
}

@media screen {

  @media (min-width:940px) {
    .choose_tags {
      max-width: 720px;
      margin-left:auto;
      margin-right:auto;
      text-align: center;
      font-size: 1.25em;
    }
  }

  @media (min-width:1367px) {
    .choose_tags {
      max-width: 940px;
      margin-left:auto;
      margin-right:auto;
      text-align: center;
    }

    .tags {
      font-size: 1.5em;
    }
  }

}

@media
  only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {

  .choose_tags {
    max-width: auto;
    text-align: center;
  }

}

.option {
  min-height: 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option span {
  margin: 0.25em;
}

.option a {
  color: #999999;
}

.continue {
  border: 0.2em solid #666666;
  border-radius: 0.2em;
  color: #666666;
  background-color: #fff;
  padding: 1em;
  padding-left:1.5em;
  padding-right: 1.5em;
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}

.wait {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.notices {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;

}

.privacy_notice, .terms_notice {
  max-width: 500px;
  font-size: 0.9em;
  color: #aaa;
  margin-left: 5em;
  margin-right: 5em;
  padding: 1em;
}

.privacy_notice a, .terms_notice a {
  cursor: pointer;
  color: #666666;
}
