.root {

}

.tag {
  font-size: 1em;
  display: inline-block;
  color: #fff;
  margin: 0.25em;
  padding: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: #999999;
  white-space: nowrap;
  border-radius: 0.25em;
  user-select: none;
  cursor: pointer;
}

.tag:first-child {
  margin-left: 0;
}

.selected {
  background-color: #666666;
}
